@import "src/assets/styles/_variables";

.Container {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  border-top: 1px solid #eeedf2;
  background: var(--color-glass-white-90);
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
  transition: $defaultTabBarTransition;
  z-index: 20;

  &.isHidden {
    bottom: -77px;
  }
}
.Part {
  display: flex;
  align-items: center;
  gap: 12px;
}

.Icon {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 16px 8px 8px;
  gap: 4px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  position: relative;
  min-width: 60px;

  svg {
    width: 24px;
    height: 24px;
  }
}

.Title {
  font-size: 10px;
  line-height: 16px;
  font-weight: 600;
  text-wrap: nowrap;
  color: var(--color-gray-80);
  &.isActive {
    color: var(--color-gray-600);
    font-size: 10px;
    font-weight: 600;
    line-height: 16px;
  }
}

.Center {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  position: relative;
  min-width: 60px;
  padding: 0 8px 3px;
  svg {
    width: 41px;
    height: 41px;
  }
  .Title {
    background: linear-gradient(#2f7ce5, #33dff6, #a154f2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.Icon {
  &.isDisabled {
    pointer-events: none;
    svg {
      path {
        stroke: var(--color-gray-50);
      }
    }
    h6 {
      color: var(--color-gray-50);
    }
  }
}
.BadgeContainer {
  display: flex;
  padding: 0 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: absolute;
  right: 7px;
  top: 7px;
  border-radius: 10px;
  background: var(--color-admin-primary);
  min-width: 14px;

  .Text {
    color: var(--color-text-black-high);
    text-align: center;
  }
}

.Badge {
  display: flex;
  width: 8px;
  height: 8px;
  padding: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 50%;
  position: absolute;
  right: 7px;
  top: 7px;
  background: var(--color-admin-primary);
}

@media (max-width: $mobileXS) {
  .Container {
    gap: 10px;
  }
  .Part {
    gap: 0;
  }
}
