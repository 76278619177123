.Modal {
  .Background {
    bottom: 0;

    .Container {
      background-color: var(--color-background-light-mode);
      border-radius: 24px 24px 0 0;
    }
  }
}

.Header {
  padding: 24px 16px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .SearchCloseButton {
    position: absolute;
    top: 20px;
    right: 16px;
  }
}

.Title {
  margin: 0 auto;
}

.Body {
  margin: 16px 16px 0;
  display: flex;
  align-items: center;
  gap: 12px;

  .Input {
    border-radius: 24px;

    .SearchInput {
      font-size: 14px;
    }
  }
}

.Wrapper {
  height: calc(100vh - 160px);
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px 16px 0;
  overflow-y: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
