@import "src/assets/styles/variables";

.Rating {
  display: flex;
  flex-direction: column;
  h5{
    color: var(--color-text-black-high);
  }
}
.RatingEmojiSelected{
  width: 24px;
  height: 24px;
  position: absolute;
  top: -10px;
  right: -5px;
  background-color: var(--color-admin-primary);
  border-radius: 50%;
  border: 2px solid var(--color-text-white);
  display: flex;
  align-items: center;
  justify-content: center;
}

.RatingTitle{
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
}
.RatingEmoji {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
  > svg {
    &:not(:last-child) {
      margin-right: 6px;
    }
  }
}
.RatingEmojiContainer{
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 6px;
}
.RatingName{
  color: var(--color-text-black-low);
}

.RatingEmojiItem {
  border-radius: 50%;
  width: 56px;
  height: 56px;
  border: 1px solid #eeedf2;
  background: var(--color-gray-20);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  img {
    width: 40px;
  }
  &.isFilled {
    border: 2px solid var(--color-admin-primary);
    background: rgba(255, 202, 64, 0.20);
    position: relative;
    scale: 1.2;
    transition: scale 0.2s ease-in-out;
  }

}
@media (max-width: $mobile) {
  .RatingEmoji {
    gap: 6px;
  }
}
