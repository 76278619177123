@import "src/assets/styles/_variables";
.Basket {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 128px;
  background-color: var(--color-gray-800);

  .OrderItemsWrapper {
    margin: 0 0 40px;

    > * + * {
      margin-top: 8px;
    }
  }
  .BasketAllItems {
    color: var(--color-text-black-high);
    margin-bottom: 4px;
  }

  .ConfirmOrderBtn {
    z-index: 5;
    position: fixed;
    bottom: 30px;
    left: 0;
    margin: 0 16px;
    width: calc(100% - 32px);
    transition: $defaultTabBarTransition;
    display: flex;
    justify-content: space-between;
    h4 {
      color: var(--color-text-white);
    }
    .GuestPayDetail {
      display: flex;
      align-items: center;
      gap: 12px;
      div {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--color-admin-primary);
      }
    }
  }

  &.isBasketEmpty {
    padding-bottom: 0;

    .BasketHeroSection {
      margin-bottom: 0;
    }
  }
}
.BasketEmptyStateContainer {
  border-radius: 24px 24px 0 0;
  background-color: var(--color-mini-button);
  min-height: calc(100dvh - 300px);
  display: flex;
  align-items: center;
}

.EmptyStateFavoritesContainer{
  min-height: calc(100dvh - 300px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.BasketMainSection {
  padding: 16px 16px 128px 16px;
  border-radius: 24px 24px 0 0;
  background: var(--color-mini-button);
}
.FavoritesTitle {
  color: var(--color-text-black-high);
}
.BasketAndFavoritesSwitch {
  background-color: var(--color-gray-30);
  height: 40px;
  border-radius: 12px;
  padding: 2px;
  label {
    font-size: 14px;
    font-weight: 600;
  }
  .background {
    background-color: var(--color-text-white);
    border-radius: 12px;
  }
}
.BasketFavoritesContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-height: calc(100dvh - 260px);
}

.AddMessageForTheRestaurant {
  margin-bottom: 60px;

  h6 {
    margin-bottom: 14px;
  }

  .AddMessageTextareaContainer {
    textarea {
      height: 111px;
      padding: 12px;
    }
  }
}
