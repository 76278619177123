@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.Container {
  position: fixed;
  top: 0;
  right: -80%;
  width: 80%;
  height: 100%;
  transition: right 0.2s ease-out;
  background-color: var(--color-gray-800);
  z-index: 2;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &.isVisible {
    right: 0;
  }
}

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    width: 28px;
    height: 20px;
  }
}

.Footer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-top: 1px solid var(--color-gray-700);
  padding-top: 16px;
}

.Socials {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.Conditions {
  display: flex;
  align-items: center;
  gap: 26px;
  justify-content: center;

  h6 {
    color: var(--color-text-medium);
  }
}

.CopyRight {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  div {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
  }

  h6 {
    color: var(--color-text-white);
  }

  .AllRightReserved {
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: var(--color-text-low);
  }
}

.Body {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
  height: 100%;
  overflow: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.Connect {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 52px;
  background: var(--color-admin-primary);
  justify-content: center;
  padding: 12px;
  margin-bottom: 16px;

  svg {
    width: 28px;
    height: 28px;
  }
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  //backdrop-filter: blur(1px);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease, visibility 0.2s ease;
  z-index: 40;

  &.isVisible {
    opacity: 1;
    visibility: visible;
  }
}

.HamburgerPrivacy {
  :global {
    .ModalBody {
      padding: 0;
    }
  }
}

@media (max-width: $mobile) {
  .HamburgerPrivacy {
    :global {
      .Modal.hasOnlyHeader .ModalBody {
        padding: 0;
      }
    }
  }
}
