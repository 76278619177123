.FilterSwitch {
  background-color: var(--color-mini-button);
  border-radius: 30px;
  width: 100%;
  padding: 8px;
  height: 52px;
  margin-bottom: 16px;

  input {
    display: none;
    //&:checked {
    //  & + label {
    //    //font-weight: bold;
    //  }
    //}
  }

  label {
    flex: 1;
    //display: flex;
    text-align: center;
    align-items: center;
    z-index: 1;
    cursor: pointer;
    border: none;
    transition: all 0.4s;
    font-weight: 500;
    font-size: 18px;
    color: var(--color-text-black);

  }


  .FilterSwitchWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .background {
    position: absolute;
    height: 100%;
    background-color: var(--color-gray);
    border-radius: 30px;
    transition: left 0.15s cubic-bezier(0.2, 0.2, 0.3, 1.3);
    top: 0;
  }
}
