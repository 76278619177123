@import "src/assets/fonts/fonts";

//Menu Settings
$preferred_primary_color_bg: #FFCA40;
$preferred_secondary_color_bg: #FFCA40;
$preferred_primary_color_text: rgba(0, 0, 0, 0.87);
$preferred_secondary_color_text: rgba(0, 0, 0, 0.87);

$preferred_primary_border_radius: 12px;

$preferred_border_radiusA: 8px;
$preferred_border_radiusB: 8px;
$preferred_border_radiusC: 8px;
$preferred_border_radiusD: 8px;

$preferred_border_radiusE: 8px;
$preferred_border_radiusF: 8px;
$preferred_border_radiusG: 8px;
$preferred_border_radiusL: 4px;
$preferred_border_radiusM: 4px;
$preferred_border_radiusK: 3px;
$preferred_border_radiusS: 4px;
$preferred_border_radiusT: 0px;
$preferred_border_radiusZ: 0px;

$preferred_marginMR: 0px;

$width_Inner_Width: 390;

//Light mode colors
$colorL_Mode: #ababab;
$colorL_Primary: #ffdd00;
$colorL_Primary_15: #fffad9;
$colorL_Primary_5: #ffca400d;
$colorL_Admin_Primary: #ffca40;
$colorL_Admin_Primary_15: rgba(255, 202, 64, 0.15);
$colorL_Admin_Primary_Hover: #ffb92a;
$colorL_Admin_Primary_Disabled: #ffe399;
$colorL_Admin_Primary_15: rgba(255, 202, 64, 0.15);

$colorL_Inverse_100: #000000;
$colorL_Inverse_85: #414141;
$colorL_Inverse_75: #676767;
$colorL_Inverse_50: #8d8d8d;
$colorL_Inverse_25: #b3b3b3;
$colorL_Blur_Black_5: rgba(0, 0, 0, 0.05);
$colorL_Blur_White_30: rgba(255, 255, 255, 0.3);
$colorL_Glass_White_90: rgba(245, 245, 245, 0.90);

$colorL_Light_Black: rgba(0, 0, 0, 0.09);
$colorL_Text_Black: #1f1f1f;
$colorL_Text_Black_High: rgba(0, 0, 0, 0.87);
$colorL_Text_White: #fff;
$colorL_Text_White_Low: rgba(255, 255, 255, 0.38);
$color-text-yellow: #fed905;
$colorL_Discount_Price: rgba(0, 0, 0, 0.38);

$colorL_Text_Medium: rgba(255, 255, 255, 0.60);
$colorL_Text_Low: rgba(255, 255, 255, 0.38);
$colorL_Text_Black_Low: rgba(0, 0, 0, 0.38);


$colorL_Gray: #d9d9d9;
$colorL_Gray_800: #0D0D0D;
$colorL_Gray_700: #1C1C1C;
$colorL_Gray_600: #2E2E2E;
$colorL_Gray_20: #F5F5F5;
$colorL_Gray_30: #ebebeb;
$colorL_Gray_40: #DEDEDE;
$colorL_Gray_50: #BFBFBF;
$colorL_Gray_80:#949494;
$colorL_Light_Gray: #e9e9e9;
$colorL_Dark_Gray: #3E3E3E;
$colorL_Very_Dark_Gray: #2E2E2E;
$colorL_Stroke: #efeded;
$colorL_Mini_Button: #f7f7f7;
$colorL_Mini_Button_Reverse: #383838;

$colorL_Secondary: #f39237;

$colorL_Error: #f34237;
$colorD_Error: #ff6b6b;
$colorL_Semantic_Error: #E3152E;
$color_Deleted_Border_Unselect: #ecc0bd;
$color_Deleted_Background_Unselect: #ffe3e1;

$colorL_Red_15: rgba(243, 66, 55, 0.15);

$colorL_Success: #4cbf4a;


$colorL_Dark_Green: #2F3128;
$colorL_Green: rgba(76, 191, 74, 0.3);
$colorL_Green_15: rgba(76, 191, 74, 0.15);
$colorL_Green_30: rgba(76, 191, 74, 0.3);

$colorL_Opacity_5: rgba(255, 202, 64, 0.05);
$colorL_Opacity_15: rgba(255, 202, 64, 0.15);
$colorL_Indigo-100: #d2d6ef;

$colorL_Gradient_Main: linear-gradient(90deg, #ffdd00 0%, #fbb034 100%);

$colorL_Gradient_Blue: linear-gradient(90deg, #5f5ff6 0%, #5c2bc4 100%);

$colorL_Gradient_LightBlue: linear-gradient(270deg, #9288FF 0%, #92F2FF 100%);

$colorL_Gradient_Brand_Secondary: linear-gradient(180deg, #4042E2 0%, #4F52FF 100%);

$colorL_Blue: #5f5ff6;
$colorL_Purple: #9747FF;
$colorL_Purple_200: #9288FF;

$colorL_Purple_Opacity_20: rgba(151, 71, 255, 0.20);
$colorL_Dark_Purple_Opacity_25: rgba(23, 12, 32, 0.25);

$colorL_Gradient_Menu_Item_Page: linear-gradient(
                180deg,
                #c3c3c3 0%,
                #f2f2f2 100%
);



$colorL_Background_Light_Mode: #fff;
$colorL_Background_Light_Mode_Black: #101010;

$colorL_Group_Background_Light_Mode: #ededed;

$colorL_Background_Dark_Mode: #404040;
$colorL_Background_Black: $colorL_Text_Black;

$colorL_Background_Modal: rgba(0, 0, 0, 0.6);

$colorL_Background_Modal_Blur: rgba(255, 255, 255, 0.05);

$colorL_Background_Yellow: #d9d43f; //for info page

$color_Under_Construction: #fdc71a;

//Dark mode colors
$colorD_Mode: #3a3a3a;

// font families
$fontBody: "Inter";

//border radius
$borderRadiusM: 8px;

//modal
$modalHeaderHeight: 70px;
$modalTopPaddingMobile: 64px;

//breakpoints
$desktop: 1280px;
$tablet: 1000px;
$mobile: 600px;
$mobileXS: 350px;

:export {
  desktopBreakpoint: $desktop;
  tabletBreakpoint: $tablet;
  mobileBreakpoint: $mobile;
}

// default transition
$defaultTransition: 150ms;

// default tab bar transition
$defaultTabBarTransition: 0.6s
